.panel {
  background: rgba(62, 59, 66, 0.5);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: 1px solid rgb(127, 114, 151);
  min-height: 100px;
  padding: 2px 10px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
}
