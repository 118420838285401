img {
  object-fit: scale-down;
  width: 100%;
  height: 500px;
}

.thumbs {
  margin: auto;
  align-self: center;

  li {
  }
}

.thumb {
  img {
    height: auto;
  }
}
