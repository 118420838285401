.header {
  height: 60px;
  width: 100%;
  background: rgba(47, 31, 77, 0.5);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);

  h2 {
    margin: 0;
    padding: 10px 20px;
  }
}
